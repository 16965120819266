
import React, {useState} from "react"

import Layout from "../../components/layout";
import WebappsForm from "../../components/webapps/form"
import WebappWidgetTable from "../../components/webapps/widget/table"


const ENTITYID="onsbooking"


const searchFields = [
	{"label":"Order Date", "dbfield": "onsbooking_datetime", "type": "datetime", "filtertype": "datetime"},
	{"label":"Order #", "dbfield": "onsorderform_code", "type": "text", "filtertype": "textbox"},
	{"label":"Seller", "dbfield": "onsbranch_name", "type": "text", "filtertype": "textbox"},
	{"label":"Seller Order #", "dbfield": "onsbooking_externcode", "type": "text", "filtertype": "textbox"},
	{"label":"Type", "dbfield": "onsorderformfulfillment_name", "type": "text", "filtertype": "textbox"},
	{"label":"Customer", "dbfield": "onscustomer_name", "type": "text", "filtertype": "textbox"},
	{"label":"Amount", "dbfield": "onsbooking_totalamount", "type": "currency", "filtertype": "currency"},
	{"label":"Status", "dbfield": "onsbookingstatus_name", "type": "text", "filtertype": "textbox"},
	{"label":"Updated", "dbfield": "onsbooking_updated", "type": "datetime", "filtertype": "datetime"}
];

const formFields = [
	[
		{"label":"Order Date", "field": "onsbooking_datetime", "value": "", "input": "datetime", "mode": "readonly"},
		{"label":"Order #", "field": "onsorderform_code", "value": "", "input": "textbox", "mode": "readonly"},
		//{"label":"Order Status ID", "field": "#onsorderformstatus_id", "value": "", "input": "hidden", "mode": "readonly"},
		{"label":"Seller", "field": "onsbranch_name", "value": "", "input": "picker", "mode": "readonly"},

		//{"label":"Customer", "field": "onscustomer_name", "value": "", "input": "picker", "mode": "readonly"},

		{"label":"Status", "field": "onsbookingstatus_name", "value": "", "input": "picker", "mode": "readonly"},
		{"label":"Reason/Details", "field": "onsbooking_locationnotes", "value":"", "input": "textarea", "mode": "readonly"},

		{"label":"Last Updated", "field": "onsbooking_updated", "value": "", "input": "datetime", "mode": "readonly"},

		{"label":"Method", "field": "onsorderformfulfillment_id", "value": "", "input": "picker", "mode": "readonly"},
		{"label":"Location/Details", "field": "nnpickup_name", "value": "", "input": "picker", "mode": "readonly"},
		{"label":"Target Date", "field": "onsbooking_targetdropoffdate", "value": "", "input": "date", "mode": "readonly"},
		{"label":"Payment Notes", "field": "onsbooking_paymentnote", "value": "", "input": "textarea", "mode": "normal"},
		{"label":"Paid", "field": "onsbooking_paid", "value": "", "input": "checkbox", "mode": "normal"},
	]
];


const subformFields = [
	{
		"subformid": "item",
		"label": "Items",
		"table": "onsbookingitem",
		"sort": "onsbookingitem_id",
		"mobilerowtitlefieldidx":[2],
		"minrow": 1,
		"maxrow": 1,
		"allowdel": false,
		"fieldlist": [
			{
				"label": "Item",
				"field": "onsproduct_name",
				"subtotalid": "",
				"value": "",
				"input": "picker",
				"mode": "readonly"
			},
			{
				"label": "Active",
				"field": "onsbookingitem_active",
				"subtotalid": "",
				"value": "",
				"input": "checkbox",
				"mode": "readonly"
			},
			{
				"label": "Product Active",
				"field": "onsproduct_active",
				"subtotalid": "",
				"value": "",
				"input": "hidden",
				"mode": "readonly"
			},
			{
				"label": "Price/Unit",
				"field": "#onsproduct_price",
				"subtotalid": "",
				"value": "",
				"input": "currency",
				"mode": "readonly"
			},
			{
				"label": "Order QTY",
				"field": "onsbookingitem_qty",
				"subtotalid": "orderpcs",
				"destfield": "",
				"value": "",
				"input": "integer",
				"mode": "readonly"
			},
			{
				"label": "Order Subtotal",
				"field": "onsbookingitem_qty*onsproduct_price*onsbookingitem_active",
				"subtotalid": "orderamount",
				"value": "",
				"input": "currency",
				"mode": "readonly"
			},
			{
				"label": "Available QTY",
				"field": "onsbookingitem_pickqty",
				"subtotalid": "availableorderpcs",
				"value": "",
				"input": "integer",
				"mode": "readonly"
			},
			{
				"label": "Available Subtotal",
				"field": "onsbookingitem_pickqty*onsproduct_price*onsbookingitem_active",
				"subtotalid": "availableorderamount",
				"value": "",
				"input": "currency",
				"mode": "readonly"
			},
			{
				"label": "Dispatched QTY",
				"field": "onsbookingitem_deliverqty",
				"subtotalid": "deliverorderpcs",
				"value": "",
				"input": "integer",
				"mode": "readonly"
			},
			{
				"label": "Dispatched Subtotal",
				"field": "onsbookingitem_deliverqty*onsproduct_price*onsbookingitem_active",
				"subtotalid": "deliverorderamount",
				"value": "",
				"input": "currency",
				"mode": "readonly"
			},
			{
				"label": "Notes",
				"field": "onsbookingitem_notes",
				"subtotalid": "",
				"value": "",
				"input": "textbox",
				"mode": "readonly"
			},
		],
		"footerlist": [
			{
				"label": "Fees",
				"field": "onsbooking_fees",
				"subtotalid": "",
				"value": "",
				"input": "currency",
				"mode": "readonly"
			},
			{
				"label": "Ordered Total",
				"field": "onsbooking_fees+orderamount",
				"subtotalid": "",
				"destfield": "onsbooking_totalamount",
				"value": "",
				"input": "currency",
				"mode": "readonly"
			},
			{
				"label": "Available Total",
				"field": "onsbooking_fees+availableorderamount",
				"subtotalid": "",
				"destfield": "onsbooking_availableamount",
				"value": "",
				"input": "currency",
				"mode": "readonly"
			},
			{
				"label": "Dispatched Total",
				"field": "onsbooking_fees+deliverorderamount",
				"subtotalid": "",
				"destfield": "onsbooking_deliveramount",
				"value": "",
				"input": "currency",
				"mode": "readonly"
			},
		]
	}
];

const orderpaymentFields = [
	{"label":"Approval Code", "dbfield": "onsorderformpayment_refno", "type": "text", "filtertype": "textbox"},
	{"label":"Date/Time", "dbfield": "onsorderformpayment_date", "type": "datetime", "filtertype": "datetime"},
	{"label":"Amount", "dbfield": "onsorderformpayment_amount", "type": "currency", "filtertype": "currency"}
];

const orderpaymentInitState = {
		"filter":[],
		"orderbyfield": "onsorderformpayment_date",
		"orderbymode": ""
	};

const OrderpaymentsPage = ({location}) => {
	const [token, setToken] = useState("");
	const [pagetitle, setPagetitle] = useState("");

	const [orderpaymentqueryparams, setOrderpaymentqueryparams] = useState({});


	function customSetFormData(newformdata)
	{
		var neworderformid = 0;


		if (newformdata.hasOwnProperty("onsorderform_id")) {
			if (newformdata.onsorderform_id) {
				neworderformid = newformdata.onsorderform_id;
			}
		}

		setOrderpaymentqueryparams({
			/*
			tablelist: ["NNPAYMENT"],
			joinlist: [
				{type:"left", table: "NNPAYMENT", condlist: ["NNPAYMENT.nnpayment_id=ONSORDERFORMPAYMENT.nnpayment_id"]},
			],*/
			condlist: ["ONSORDERFORMPAYMENT.onsorderform_id="+neworderformid]
		})
	}

	function customFieldFragment(inputlist, idx)
	{
		if (inputlist[idx].field === "onsbooking_paid") {

			return <>
				<WebappWidgetTable
								title={"Payment Details"}
								token={token}
								entity={"ONSORDERFORMPAYMENT"}
								fieldList={orderpaymentFields}
								mobilerowtitlefield={[]}
								filterfieldList={[]}
								userparam={orderpaymentqueryparams}
								initsearchstate={orderpaymentInitState}
								showControls={false}
								showRefresh={false}
							/>
			</>
		}
		// default
		return null;
	}

	return <Layout location={location}
			newtokenHandler={(newtoken)=>{setToken(newtoken)}}
			newPageTitle={(newtitle)=>{setPagetitle(newtitle)}}
			privatePage={true} usegatedcontent={true}>
			<WebappsForm
				location={location}
				allowadd={false}
				allowdelete={false}
				pagetitle={pagetitle}
				mobilerowtitlefield={["onsorderform_code","onsbooking_datetime"]}
				userSearchParam={{"orderby":"onsbooking_datetime desc", "joincond": "ONSORDERFORM.onsorderform_id=ONSBOOKING.onsorderform_id and ONSBRANCH.onsbranch_id=ONSBOOKING.onsbranch_id and ONSORDERFORMFULFILLMENT.onsorderformfulfillment_id=ONSBOOKING.onsorderformfulfillment_id and ONSBOOKINGSTATUS.onsbookingstatus_id=ONSBOOKING.onsbookingstatus_id and ONSCUSTOMER.onscustomer_id=ONSBOOKING.onscustomer_id"}}

				searchFields={searchFields}

				customSetFormData={customSetFormData}
				customFieldFragment={customFieldFragment}
				editFields={formFields}
				editSubFormFields={subformFields}
				entity={ENTITYID}
				token={token} />
		</Layout>

}


export default OrderpaymentsPage;
